<template>
  <v-container>
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row ref="form">
            <v-col class="pt-0 pb-0" cols="6">
              <v-text-field
                ref="name"
                v-model="discount.name"
                :rules="[() => !!discount.name]"
                dense
                label="Название:"
              />
            </v-col>

            <v-col class="pt-0 pb-0" cols="6">
              <v-select
                v-model.number="discount.discount_kind"
                :rules="[() => !!discount.discount_kind]"
                :items="kindItems"
                item-text="name"
                item-value="value"
                label="Вид скидки"
                dense
              ></v-select>
            </v-col>
            <v-col class="pt-0 pb-0" cols="6">
              <v-text-field
                type="number"
                v-model="discount.quantity"
                :rules="[() => !!discount.quantity]"
                dense
                label="Количество"
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="6">
              <v-text-field
                v-model="discount.promocode"
                :rules="[() => !!discount.promocode]"
                dense
                label="Промокод"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="close"> Отменить </v-btn>
        <v-btn color="primary" text @click="save" :disabled="disabledBtn">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { ServiceApiUrls } from "@/services/accommodationRequests/service.api.js";
import toast from "../../../plugins/toast";

export default {
  name: "SingleDiscount",
  data() {
    return {
      disabledBtn: false,
      kindItems: [
        { name: "Фиксированная", value: "fixed" },
        { name: "В процентах", value: "percent" }
      ],
      discount: {
        name: null,
        discount_type: "once",
        discount_kind: null,
        quantity: null,
        promocode: null,
        company: null
      }
    };
  },
  computed: {
    formTitle() {
      return this.$route.params.id ? "Редактировать" : "Создать";
    }
  },
  async created() {
    if (this.$route.params.id) {
      let res = await ServiceApiUrls.getDiscount(this.$route.params.id);
      this.discount = res;
    }
  },
  methods: {
    async save() {
      if (this.$route.params.id) {
        this.discount.company = localStorage.getItem("company_id");
        this.disabledBtn = true;

        try {
          await ServiceApiUrls.setDiscount(
            this.$route.params.id,
            this.discount
          );
          this.disabledBtn = false;
          toast.success("Успешно изменено!");
          this.close();
        } catch (e) {
          this.disabledBtn = false;
        }
      } else {
        this.discount.company = localStorage.getItem("company_id");
        this.disabledBtn = true;
        try {
          await ServiceApiUrls.createDiscount(this.discount);
          this.disabledBtn = false;
          toast.success("Успешно создано!");
          this.close();
        } catch (e) {
          this.disabledBtn = false;
        }
      }
    },
    close() {
      this.$router.push("/discount_list");
    }
  }
};
</script>

<style scoped></style>
