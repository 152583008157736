const axios = window.axios
const url = '/api/v2/service-discount/discounts/'
const urlServices = '/api/v2/service-discount/services/'


export const ServiceApiUrls = {
    // discounts скидки и купоны в настройках
    async getDiscounts(params){
        return (await axios.get(`${url}`, { params: params })).data;
    },
    async createDiscount(body){
        return (await axios.post(`${url}`, body)).data;
    },
    async getDiscount(id){
        return (await axios.get(`${url}${id}/`)).data;
    },
    async setDiscount(id, body){
        return (await axios.put(`${url}${id}/`, body)).data;
    },
    async deleteDiscount(id){
        return (await axios.delete(`${url}${id}/`)).data;
    },



    // services услуги в настройках
    async getServices(params){
        return (await axios.get(`${urlServices}`, { params: params })).data;
    },
    async createService(body){
        return (await axios.post(`${urlServices}`, body)).data;
    },
    async getService(id){
        return (await axios.get(`${urlServices}${id}/`)).data;
    },
    async setService(id, body){
        return (await axios.put(`${urlServices}${id}/`, body)).data;
    },
    async deleteService(id){
        return (await axios.delete(`${urlServices}${id}/`)).data;
    },
}